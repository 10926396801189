<template>
    <auth-layout>
        <auth-form :action="action" :callback="submit">
            <template #header>
                <h1>{{ $t('auth_registration.title') }}</h1>
                <p>
                    {{ $t('auth_registration.description') }}
                    <router-link :to="{ name: 'auth-login' }">{{ $t('auth_registration.login') }}</router-link>
                </p>
            </template>

            <validation-provider name="email" rules="required|email" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_registration.email.label')" :errors="errors">
                    <input-component
                        type="email"
                        v-model="email"
                        :placeholder="$t('auth_registration.email.placeholder')"
                        @input="reset"
                        @mousedown.stop
                    />
                </input-group>
            </validation-provider>

            <validation-provider name="website" rules="required|url" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_registration.website.label')" :errors="errors">
                    <input-component
                        type="text"
                        v-model="website"
                        :placeholder="$t('auth_registration.website.placeholder')"
                        @input="reset"
                        @mousedown.stop
                    />
                </input-group>
            </validation-provider>

            <validation-provider name="password" rules="required|min:8" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_registration.password.label')" :errors="errors">
                    <password-input
                        type="password"
                        v-model="password"
                        :placeholder="$t('auth_registration.password.placeholder')"
                        @input="reset"
                        @mousedown.stop
                    />
                </input-group>
            </validation-provider>

            <validation-provider name="password_confirmation" rules="required|confirmed:password" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_registration.password_confirmation.label')" :errors="errors">
                    <password-input
                        type="password"
                        v-model="passwordConfirmation"
                        :placeholder="$t('auth_registration.password_confirmation.placeholder')"
                        @input="reset"
                        @mousedown.stop
                    />
                </input-group>
            </validation-provider>

            <template #button>
                {{ $t('auth_registration.submit_button') }}
                <arrow-right-icon />
            </template>
        </auth-form>
    </auth-layout>
</template>

<script>
    import { DateTime } from 'luxon';
    import { ValidationProvider } from 'vee-validate';
    import AuthForm from '@/components/auth/AuthForm';
    import TextButton from '@/components/buttons/TextButton';
    import ArrowRightIcon from '@/components/icons/ArrowRightIcon';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';
    import PasswordInput from '@/components/inputs/PasswordInput';
    import Toast from '@/components/Toast';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import { application } from '@/services/skinsPaymentApi';
    import { REGISTRATION_CODE_TIMEOUT } from '@/services/skinsPaymentApi/constants/common';

    export default {
        name: 'Registration',
        components: {
            ValidationProvider,
            AuthForm,
            TextButton,
            ArrowRightIcon,
            InputComponent,
            InputGroup,
            PasswordInput,
            AuthLayout,
        },
        computed: {
            action() {
                return 'landing-api/applications';
            },
        },
        methods: {
            submit() {
                return application(this.email, this.website, this.password, this.passwordConfirmation).then(({ data }) => {
                    sessionStorage.setItem('AUTH_REGISTRATION_APPLICATION_ID', data.id);
                    sessionStorage.setItem('AUTH_REGISTRATION_EMAIL', this.email);
                    sessionStorage.setItem(
                        'AUTH_REGISTRATION_TIMER_END',
                        DateTime.now().plus({ seconds: REGISTRATION_CODE_TIMEOUT }).toUnixInteger()
                    );

                    this.$toast({
                        component: Toast,
                        props: {
                            type: 'success',
                            text: this.$t('auth_registration.toasts.submit_success'),
                        },
                    });

                    this.$router.push({ name: 'auth-registration-code' });
                });
            },
        },
        data() {
            return {
                email: '',
                website: '',
                password: '',
                passwordConfirmation: '',
            };
        },
    };
</script>
